<template>
  <div class="main_container">
    <div class="row my-3">
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"
                v-if="role[1] == 'FER' && role[0]=='DAF' || role[0]=='DCG' || role[0]=='ADMIN'"> Imprimer </button>
      </div>
    </div>
    <!-- ///////////////////////////////////////////////////////////////////////// ZONE IMPRIMABLE ///////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- Vue-html2pdf -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1225"
                  :filename="'Vue_global_activité'+jour"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"

                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf">
      <div slot="pdf-content">
        
      </div>
    </vue-html2pdf>
    <!--/////////////////////////////////////////////////////////////////////////////////////////////////////////-->
    <template v-if="role[1]=='FER'">
      <div class="row my-3"
           v-if="role[0]=='DT'">
        <div class="col-md-2 ml-5 badge-index">
          <div class="row">
            <div class="col-md-9">
              <h5 class="mt-3">
                {{ totaux.decomptes_recus.toLocaleString() }}
              </h5>
            </div>
            <div class="col-md-2">
              <h5 class="mt-3"><i class="flaticon-files"></i></h5>
            </div>
          </div>
          <div class="row pl-2">
            <h6 class="mb-3">Décomptes reçus</h6>
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-2 badge-index">
          <div class="row">
            <div class="col-md-9">
              <h5 class="mt-3">{{totaux.decompte_retournes.toLocaleString()}}</h5>
            </div>
            <div class="col-md-2">
              <h5 class="mt-3"><i class="flaticon-files"></i></h5>
            </div>
          </div>
          <div class="row pl-2">
            <h6 class="mb-3">Décomptes retournés</h6>
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-2 badge-index">
          <div class="row">
            <div class="col-md-9">
              <h5 class="mt-3">{{ totaux.total_en_attente.toLocaleString() }}</h5>
            </div>
            <div class="col-md-2">
              <h5 class="mt-3"><i class="flaticon-files"></i></h5>
            </div>
          </div>
          <div class="row pl-2">
            <h6 class="mb-3">Décomptes en attente</h6>
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-2 badge-index">
          <div class="row">
            <div class="col-md-9">
              <h5 class="mt-3">{{totaux.total_paiement.toLocaleString()}}</h5>
            </div>
            <div class="col-md-2">
              <h4 class="mt-3"><i class="flaticon-files"></i></h4>
            </div>
          </div>    
          <div class="row pl-2">
            <h6 class="mb-3">Décomptes payés</h6>
          </div>
        </div>
      </div>
      <div class="row my-3"
           v-if="role[0]=='DAF'">
        <div class="col-md-2 ml-5 badge-index">
          <div class="row">
            <div class="col-md-9">
              <h5 class="mt-3">{{ totaux.decomptes_recus.toLocaleString() }}</h5>
            </div>
            <div class="col-md-2">
              <h4 class="mt-3"><i class="flaticon-files"></i></h4>
            </div>
          </div>
          <div class="row pl-2">
            <h6 class="mb-3">Décomptes reçus</h6>
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-2 badge-index">
          <div class="row">
            <div class="col-md-9">
              <h5 class="mt-3">{{totaux.total_paiement.toLocaleString()}}</h5>
            </div>
            <div class="col-md-2">
              <h4 class="mt-3"><i class="flaticon-files"></i></h4>
            </div>
          </div>
          <div class="row pl-2">
            <h6 class="mb-3">Décomptes payés</h6>
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-2 badge-index">
          <div class="row">
            <div class="col-md-9">
              <h5 class="mt-3">{{Math.round(echeance_a_couvrir).toLocaleString()}}</h5>
            </div>
            <div class="col-md-2">
              <h4 class="mt-3"><i class="flaticon-files"></i></h4>
            </div>
          </div>
          <div class="row pl-2">
            <h6 class="mb-3">Dette à régler</h6>
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-2 badge-index">
          <div class="row">
            <div class="col-md-9">
              <h5 class="mt-3">{{Math.round(total.domiciliation_provision.situation_a_date).toLocaleString()}}</h5>
            </div>
            <div class="col-md-2">
              <h4 class="mt-3"><i class="flaticon-files"></i></h4>
            </div>
          </div>    
          <div class="row pl-2">
            <h6 class="mb-3">Provision constituée</h6>
          </div>
        </div>
      </div>
      <div class="row my-3"
           v-if="role[0]=='DCG'">
        <div class="col-md-2 ml-5 badge-index">
          <div class="row">
            <div class="col-md-9">
              <h4 class="mt-3">{{indicateur.tcb.toLocaleString()}}%</h4>
            </div>
            <div class="col-md-2">
              <h4 class="mt-3"><i class="flaticon-files"></i></h4>
            </div>
          </div>
          <div class="row pl-2">
            <h6 class="mb-3">Taux de couverture des besoins</h6>
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-2 badge-index">
          <div class="row">
            <div class="col-md-9">
              <h4 class="mt-3">{{indicateur.tl.toLocaleString()}}%</h4>
            </div>
            <div class="col-md-2">
              <h4 class="mt-3"><i class="flaticon-files"></i></h4>
            </div>
          </div>
          <div class="row pl-2">
            <h6 class="mb-3">Taux de liquidité</h6>
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-2 badge-index">
          <div class="row">
            <div class="col-md-9">
              <h4 class="mt-3">{{indicateur.tmra.toLocaleString()}}%</h4>
            </div>
            <div class="col-md-2">
              <h4 class="mt-3"><i class="flaticon-files"></i></h4>
            </div>
          </div>
          <div class="row pl-2">
            <h6 class="mb-3">Taux de mobilisation des ressources affectées</h6>
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-2 badge-index">
          <div class="row">
            <div class="col-md-9">
              <h4 class="mt-3">{{indicateur.tmr.toLocaleString()}}%</h4>
            </div>
            <div class="col-md-2">
              <h4 class="mt-3"><i class="flaticon-files"></i></h4>
            </div>
          </div>    
          <div class="row pl-2">
            <h6 class="mb-3">Taux de mobilisation des ressources</h6>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- <div class="row my-3">
        <div class="col-md-2 ml-5 badge-index">
          <div class="row">
            <div class="col-md-9">
              <h4 class="mt-3">459B</h4>
            </div>
            <div class="col-md-2">
              <h4 class="mt-3"><i class="flaticon-files"></i></h4>
            </div>
          </div>
          <div class="row pl-2">
            <h6 class="mb-3">Titre à fournir</h6>
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-2 ml-5 badge-index">
          <div class="row">
            <div class="col-md-9">
              <h4 class="mt-3">459B</h4>
            </div>
            <div class="col-md-2">
              <h4 class="mt-3"><i class="flaticon-files"></i></h4>
            </div>
          </div>
          <div class="row pl-2">
            <h6 class="mb-3">Titre à fournir</h6>
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-2 ml-5 badge-index">
          <div class="row">
            <div class="col-md-9">
              <h4 class="mt-3">459B</h4>
            </div>
            <div class="col-md-2">
              <h4 class="mt-3"><i class="flaticon-files"></i></h4>
            </div>
          </div>
          <div class="row pl-2">
            <h6 class="mb-3">Titre à fournir</h6>
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-2 ml-5 badge-index">
          <div class="row">
            <div class="col-md-9">
              <h4 class="mt-3">459B</h4>
            </div>
            <div class="col-md-2">
              <h4 class="mt-3"><i class="flaticon-files"></i></h4>
            </div>
          </div>    
          <div class="row pl-2">
            <h6 class="mb-3">Titre à fournir</h6>
          </div>
        </div>
      </div> -->
    </template>
    <!-- Affichage FER -->
    <template v-if="role[1]=='FER'">
      <!-- Affichage DT -->
      <div class="row mt-5"
           v-if="role[0]=='DT'">
        <div class="col-md-12 mr-3 ml-4"
             style="border-radius: 5px;">
          <div class="row mt-2">
            <div class="col-md-8">
              <h4>Décomptes récents</h4>
            </div>
            <div class="col-md-4"
                 style="border-radius: 5px;">
              <button class="btn btn-round btn-blue"
                      @click="chargerPage('decompte')">Voir plus &#x21E8;</button>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <table class="table table-striped">
                <thead>
                  <tr style="color: #FFF;background-color: #303030;">
                    <th class="th-blue"
                        style="width: 20%;">Programme</th>
                    <th class="th-blue"
                        style="width: 20%;">Marché</th>
                    <th class="th-blue"
                        style="width: 20%;">N° décompte</th>
                    <th class="th-blue"
                        style="width: 20%;">Statut</th>
                    <th class="th-blue text-right"
                        style="width: 20%;">Montant (FCFA)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(decompte,deckey) in listeDecompteIndex"
                      :key="deckey">
                    <td>{{ decompte.programme.nom_programme }}</td>
                    <td>{{ decompte.marche.numero_marche }}</td>
                    <td>{{ decompte.numero_decompte }}</td>
                    <td>{{ decompte.statut_final }}</td>
                    <td class="text-right">{{ Number(decompte.montant_facture_decompte).toLocaleString() }}</td>
                  </tr>
                </tbody>
              </table>
            </div>      
          </div>
        </div>
      </div>
      <!-- Affichage DAF -->
      <div class="row mt-5"
           v-if="role[0]=='DAF'">
        <div class="col-md-7"
             style="border-radius: 5px;">
          <div class="row mt-2">
            <div class="col-md-8">
              <h4>Décomptes récents</h4>
            </div>
            <div class="col-md-4"
                 style="border-radius: 5px;">
              <button class="btn btn-round btn-blue"
                      @click="chargerPage('decompte')">Voir plus &#x21E8;</button>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <table class="table table-striped">
                <thead>
                  <tr style="color: #FFF;background-color: #303030;">
                    <th class="th-blue"
                        style="width: 20%;">Programme</th>
                    <th class="th-blue"
                        style="width: 20%;">Marché</th>
                    <th class="th-blue"
                        style="width: 20%;">N° décompte</th>
                    <th class="th-blue"
                        style="width: 20%;">Statut</th>
                    <th class="th-blue text-right"
                        style="width: 20%;">Montant (FCFA)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(decompte,deckey) in listeDecompteIndex"
                      :key="deckey">
                    <td>{{ decompte.programme.nom_programme }}</td>
                    <td>{{ decompte.marche.numero_marche }}</td>
                    <td>{{ decompte.numero_decompte }}</td>
                    <td>{{ decompte.statut_final }}</td>
                    <td class="text-right">{{ Number(decompte.montant_facture_decompte).toLocaleString() }}</td>
                  </tr>
                </tbody>
              </table>
            </div>      
          </div>
        </div>
        <div class="col-md-5"
             style="border-radius: 5px;">
          <div class="row mt-2">
            <div class="col-md-7"><h4>Dettes à régler</h4></div>
            <div class="col-md-4"
                 style="border-radius: 5px;">
              <button class="btn btn-round btn-blue"
                      @click="chargerPage('dettes')">Voir plus &#x21E8;</button>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12"
                 style="border-radius: 5px;">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th class="th-blue">Prêteur</th>
                    <th class="th-blue text-right">Montant du prêt</th>
                    <th class="th-blue ">date de l'écheance</th>
                    <th class="th-blue text-right">Montant de l'écheance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(dette, detkey) in listDette"
                      :key="detkey">
                    <td>{{ dette.banque }}</td>
                    <td class="text-right">{{ Math.round(Number(dette.montant_pret)).toLocaleString() }}</td>
                    <td>{{ dette.date_echeance_proche }}</td>
                    <td class="text-right">{{ Math.round(Number(dette.montant_echeance)).toLocaleString() }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Affichage DCG -->
      <div class="row mt-5"
           v-if="role[0]=='DCG'">
        <div class="col-md-12 mr-3 ml-4"
             style="border-radius: 5px;">
          <div class="row mt-2">
            <div class="col-md-8">
              <h4>Balance </h4>
            </div>
            <div class="col-md-4"
                 style="border-radius: 5px;">
              <button class="btn btn-round btn-blue"
                      @click="chargerPage('balanceCG')">Voir plus &#x21E8;</button>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <table class="table table-striped">
                <thead>
                  <tr style="color: #FFF;background-color: #303030;">
                    <th class="th-blue"
                        style="width: 20%;">Compte</th>
                    <th class="th-blue"
                        style="width: 20%;">Libellé</th>
                    <th class="th-blue"
                        style="width: 20%;">Budget définitif</th>
                    <th class="th-blue"
                        style="width: 20%;">Réalisation</th>
                    <th class="th-blue text-right"
                        style="width: 20%;">Ajustement réalisation</th>
                    <th class="th-blue text-right"
                        style="width: 20%;">Ecart</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(compte,compkey) in comptes.donnees"
                      :key="compkey">
                    <td v-if="compte.compte!=null">{{compte.compte}}</td>
                    <td v-else
                        class="txt-blue">999999999</td>
                    <td>{{compte.libelle}}</td>
                    <td class="text-right">{{Math.round(Number(compte.budget_definitif)).toLocaleString()}}</td>
                    <td class="text-right">{{Math.round(Number(compte.realisation)).toLocaleString()}}</td>
                    <td class="text-right">
                      {{Math.round(compte.ajustement_realisation).toLocaleString()}}
                    </td>
                    <td class="text-right"
                        :class="compte.color">
                      {{Math.round(compte.ecart).toLocaleString()}}  
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>      
          </div>
        </div>
      </div>
    </template>
    <!-- Affichage AGEROUTE/DGIR -->
    <template v-else>
      <div class="row mt-5"></div>
      <!-- Affichage DT -->
      <div class="row mt-5"
           v-if="role[1]=='DGIR' || role[1]=='AGEROUTE'">
        <div class="col-md-12 mr-3 ml-4"
             style="border-radius: 5px;">
          <div class="row mt-2">
            <div class="col-md-8">
              <h4 v-if="role[1]=='AGEROUTE'">Décomptes récents</h4>
              <h4 v-else>Factures récentes</h4>
            </div>
            <div class="col-md-4"
                 style="border-radius: 5px;">
              <button class="btn btn-round btn-blue"
                      @click="chargerPage('decompte')">Voir plus &#x21E8;</button>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <table class="table table-striped">
                <thead>
                  <tr style="color: #FFF;background-color: #303030;">
                    <th class="th-blue"
                        style="width: 20%;">Programme</th>
                    <th class="th-blue"
                        style="width: 20%;"
                        v-if="role[1]=='AGEROUTE'">Marché</th>
                    <th class="th-blue"
                        style="width: 20%;"
                        v-else>Lettre de commande</th>
                    <th class="th-blue"
                        style="width: 20%;"
                        v-if="role[1]=='AGEROUTE'">N° décompte</th>
                    <th class="th-blue"
                        style="width: 20%;"
                        v-else>N° de lettre de commande</th>
                    <th class="th-blue"
                        style="width: 20%;">Statut</th>
                    <th class="th-blue text-right"
                        style="width: 20%;">Montant (FCFA)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(decompte,deckey) in listeDecompteIndex"
                      :key="deckey">
                    <td>{{ decompte.programme.nom_programme }}</td>
                    <td>{{ decompte.marche.numero_marche }}</td>
                    <td>{{ decompte.numero_decompte }}</td>
                    <td>{{ decompte.statut_final }}</td>
                    <td class="text-right">{{ Number(decompte.montant_facture_decompte).toLocaleString() }}</td>
                  </tr>
                </tbody>
              </table>
            </div>      
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<style>
@import "./dashboard.css";
</style>
<script>
// import VCalendar from "./Calendar.vue"
// import axios from "axios"
import { mapActions, mapGetters, mapMutations } from "vuex"
// import utils from "@/assets/js/utils"
// import VueHtml2pdf from 'vue-html2pdf'
export default {
  name: "Dashboard",
  // components: {
  //   VCalendar,
  //   VueHtml2pdf
  // },
  data: () => ({
    montant_caisse: 0,
    credit: 0,
    debit: 0,
    year:{
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",
    },
    listeDecompteIndex:[],
    groupe:"fer",
    decomptePer:"",
    totaux:{
      decomptes_recus: 0,
      decompte_retournes: 0,
      total_en_attente: 0,
      total_paiement: 0,
    },
    annee_debut: new Date().getFullYear()+"-01-01",
    annee_fin: new Date().getFullYear()+"-12-31",
    sorties:[],
    entrees:[],
    solde_tresorerie:[],
    dettes:[],
    annees:"",
    nbDecompteAValider: 0,
    decompteAValider: [],
    validateData: {
      libelle: null,
      order: "desc",
      tri: null,
      en_attente: 1
    },
    indicateur:{
      tcb:0,
      tl:0,
      tmra:0,
      tmr:0,
    },
    dateJour:"",
    long:0,
    total:{
      travaux:{situation_a_date:0},
      fonctionnement_investissements:{situation_a_date:0},
      domiciliation_provision:{situation_a_date:0},
      sequestre:{situation_a_date:0},
      autre:{situation_a_date:0},
      total:{situation_a_date:0},
    },
    totauxAnnuel:{
      principal:0,
      interet:0,
      total:0,
      total_remboursement:0,
      total_reste_a_payer:0,
    },
    listDette:[],
    echeance_a_couvrir:0,
    garanti_necessaire:0,
    jour:"",
    charge:false,
    yearData: null,
    oldDecompte: [],
    opComptable:[],
    nbNewDecomptes: 0,
    role: "",
    meteo: "",
    market: [],
    comptes:{
      pagination:"",
      donnees:[]
    }
  }),
  created() {
    console.log(this.$route.name)
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.role = userdetails.user_role.split("_")
    if (this.role[0]!="DCG") {
      this.Decomptes({groupe:this.role[1],filtre:[]})
    
      this.getReportingDecompte({annee_debut:this.annee_debut,annee_fin:this.annee_fin})
      if (this.role[0]=="DAF") {
        this.loadTresorerieSiege({date:this.dateJour,base:"SIEGE"})
      }
    }else{
      this.loadIndicateurs1({annee_debut:this.annee_debut,annee_fin:this.annee_fin,type:"taux_couverture_besoins2",indicateur:"taux-couverture-besoins"})
    
      this.loadJournalCG({
        annee_debut:this.annee_debut,
        annee_fin:this.annee_fin,
        base:"SIEGE",
        periode:"ANNUELLE",
        classe:""
      })
    }
    
    var dateToday = new Date()
    this.dateJour += dateToday.getFullYear()+"-"
    
    if ((dateToday.getMonth()+1)<10) {
      this.dateJour +="0"+ (dateToday.getMonth()+1)+"-"
    }else{
      this.dateJour += (dateToday.getMonth()+1)+"-"
    }
    
    if (dateToday.getDate()<10) {
      this.dateJour +="0"+ dateToday.getDate()
    }else{
      this.dateJour += dateToday.getDate()
    }
    
    
  },
  watch: {
    decomptes(){
      if(this.decomptes){
        console.log(this.decomptes)
        for (let index = 0; index < 5; index++) {
          this.listeDecompteIndex.push(this.decomptes.donnees[index])
        }
      }
    },
    reportingDecompte(){
      if (this.reportingDecompte) {
        this.decomptePer = this.reportingDecompte.donnees
        this.totaux.decomptes_recus= 0
        this.totaux.decompte_retournes= 0
        this.totaux.total_en_attente= 0
        this.totaux.total_paiement= 0

        this.decomptePer.forEach(per => {
          this.totaux.decomptes_recus += Number(per.decomptes_recus)
          this.totaux.decompte_retournes += Number(per.decompte_retournes)
          this.totaux.total_en_attente += Number(per.total_en_attente)
          this.totaux.total_paiement += Number(per.total_paiement)
        })
      }
    },
    tresorerieSiege(){
      if (this.tresorerieSiege) {
        this.total={
          domiciliation_provision:{situation_a_date:0},
          sequestre:{situation_a_date:0},
        }
        // Création des formualaires par ligne
        this.tresorerieSiege.donnees.forEach(siege => {
          // calcul des totaux
          
          if (siege.groupe=='COMPTES_PROVISION') {
            
            this.total.domiciliation_provision.situation_a_date+=siege.situation_a_date
          }
          if (siege.groupe=='COMPTES_RESERVE') {

            this.total.sequestre.situation_a_date+=siege.situation_a_date
          }
          
        })
        console.log(this.total)
        this.loadPaiementProche({date_debut:this.dateJour,nb_jour:90,base:"SIEGE"})
      }
    },
    paiementProche(){
      if(this.paiementProche){
        this.echeance_a_couvrir=0
        this.garanti_necessaire= 0
        this.listDette=[]
        
        if (this.paiementProche.donnees) {
          this.paiementProche.donnees.forEach(paiement => {
            this.echeance_a_couvrir+=Number(paiement.montant_echeance)
            this.garanti_necessaire+=Number(paiement.garantie_necessaire)
            this.listDette.push(paiement)
          })
        }

      }
    },
    indicateurs1(){
      if (this.indicateurs1) {
        this.indicateur.tcb= Math.round(Number(this.indicateurs1) * 100) / 100
        this.loadIndicateurs2({annee_debut:this.annee_debut,annee_fin:this.annee_fin,type:"taux_liquidite2",indicateur:"taux-liquidite"}) 
      }
    },
    indicateurs2(){
      if (this.indicateurs2) {
        this.indicateur.tl=Math.round(Number(this.indicateurs2) * 100) / 100
        this.loadIndicateurs3({annee_debut:this.annee_debut,annee_fin:this.annee_fin,type:"i_taux_mobilisation_ressources_affectees2",indicateur:"taux-mobilisation-ressources-affectees"}) 
      }
    },
    indicateurs3(){
      if (this.indicateurs3) {
        this.indicateur.tmra=Math.round(Number(this.indicateurs3) * 100) / 100
        this.loadIndicateurs4({annee_debut:this.annee_debut,annee_fin:this.annee_fin,type:"i_taux_mobilisation_ressources2",indicateur:"taux-mobilisation-ressources"}) 
      }
    },
    indicateurs4(){
      if (this.indicateurs4) {
        this.indicateur.tmr=Math.round(Number(this.indicateurs4) * 100) / 100 
      }
    },
    journalCG(){
      if (this.journalCG) {
        this.loaded=true
        this.comptes={
          pagination:"",
          donnees:[]
        }
        this.comptes.pagination=this.journalCG.pagination
        var count=0
        this.journalCG.donnees.forEach(journal => {
          this.comptes.donnees.push({
            ajustement_budget:journal.ajustement_budget,
            ajustement_realisation:journal.ajustement_realisation,
            budget:journal.budget,
            budget_definitif:journal.budget+journal.ajustement_budget,
            compte:journal.compte,
            credit:journal.credit,
            debit:journal.debit,
            id:journal.id,
            libelle:journal.libelle,
            realisation:journal.realisation,
            ecart:0,
            color:""
          })
          this.comptes.donnees[count].ecart= this.comptes.donnees[count].budget_definitif - this.comptes.donnees[count].realisation,
          this.comptes.donnees[count].color= this.comptes.donnees[count].budget_definitif - this.comptes.donnees[count].realisation < 0 ? "bad-news":"good-news"
          count++
        })
        
      }
    },
  },
  computed: {
    ...mapGetters(["journalCG","decomptes","reportingDecompte","tresorerieSiege","msgFailTresorerieSiege","msgFailPaiementpaiementProche","paiementProche","indicateurs1","indicateurs2","indicateurs3","indicateurs4"])
  },
  methods: {
    ...mapActions([ "loadJournalCG","Decomptes","getReportingDecompte","loadTresorerieSiege","loadPaiementProche","loadIndicateurs1","loadIndicateurs2","loadIndicateurs3","loadIndicateurs4"]),
    ...mapMutations(["setJournalCG","setDecomptes","setReportingDecompte","setTresorerieSiege","setMsgFailTresorerieSiege","setMsgFailPaiementProche","setPaiementProche","setIndicateurs1","setIndicateurs2","setIndicateurs3","setIndicateurs4"]),
    chargerPage(key){
      if (this.role[1]=="FER") {
        if (key=="decompte") {
          this.$router.push({ name: "DecompteValider"})
        }else{
          if (key=="balance") {
            this.$router.push({ name: "Comptabilite"})
          }else{
            if (key=="balanceCG") {
              this.$router.push({ name: "ControleDeGestion"})
            }else{
              this.$router.push({ name: "LeveeDeFonds"})
            }
          }
        }  
      }else{
        if (key=="decompte") {
          this.$router.push({ name: "decompte"})
        }
      }
    },
  }, 
  // beforeDestroy() {
  //   this.cancelAutoUpdate()
  // }
}
</script>
